/// (UIX.Configuration.ts is generated by the provided build tools)
/// <reference path="../UIX.Configuration.ts" />

/// <reference path="Polyfill/Polyfills.ts" />
/// <reference path="Core/Static/Initialization.ts" />
/// <reference path="Localization/Index.ts" />

/// <reference path="Interface/AjaxInterface.ts" />
/// <reference path="Interface/ServiceWorkerInterface.ts" />

/// <reference path="Libraries/Index.ts" />

/// <reference path="Core/Tools/ClearCache.ts" />
/// <reference path="WidgetSystem/Render.ts" />