namespace UIX.Libraries.Hint{
    export enum HintPosition{
        Auto,
        Top,
        Bottom,
        Left,
        Right,
        TopLeft,
        TopRight,
        BottomLeft,
        BottomRight
    }
}